import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo/logo.png';
import logowhite from '../../assets/images/logo/logowhite.png';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

export default function Footer() {
    return <>
        <div className="footer-area">
            <div className="footer-container">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="200">
                                <div className="single-wedge">
                                    <h4 className="footer-herading">about us</h4>
                                    <p className="about-text">Elevate your space with premium furniture that complements your decor and enhances your everyday comfort with flexible rental options.
                                    </p>
                                    {/* <ul className="link-follow">
                                   <li className="li">
                                        <Link className="facebook icon-social-facebook" title="Facebook" to="/"></Link>
                                    </li>
                                    <li className="li">
                                        <Link className="instagram icon-social-instagram" title="Instagram" to="/"></Link>
                                    </li>
                                    <li className="li">
                                        <Link className="linkedin icon-social-linkedin" title="Linkedin" to="/"></Link>
                                    </li>
                                </ul> */}
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-lg-3 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="400">
                                <div className="single-wedge">
                                    <h4 className="footer-herading">information</h4>
                                    <div className="footer-links">
                                        <div className="footer-row">
                                            <ul className="align-items-center">
                                                <li className="li"><Link className="single-link" to="https://web.asanafurniture.com/privacy-policy">Privacy Policy</Link></li>
                                                <li className="li"><Link className="single-link" to="https://web.asanafurniture.com/terms-conditions">Terms & Conditions</Link></li>
                                                <li className="li"><Link className="single-link" to="https://web.asanafurniture.com/return-and-refund-policy">Return & Refund Policy</Link></li>
                                                

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-2 col-sm-6 mb-lm-30px" data-aos="fade-up" data-aos-delay="600">
                                <div className="single-wedge">
                                    <h4 className="footer-herading">my account</h4>
                                    <div className="footer-links">
                                        <div className="footer-row">
                                            <ul className="align-items-center">

                                                <li className="li"><Link className="single-link" to="/my-account">My Account</Link></li>
                                                <li className="li"><Link className="single-link" to="/login">Login</Link></li>
                                                <li className="li"><Link className="single-link" to="/register">Register</Link></li>
                                                <li className="li"><Link className="single-link" to="/order-history">Order Details</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay="800">
                                <div className="single-wedge">
                                    <h4 className="footer-herading">Contact</h4>
                                    <div className="footer-links">
                                        <div className="footer-row">
                                            <ul className="align-items-center">

                                                <li className="li"><Link className="ion-ios-telephone" title="Linkedin" to="/" style={{ fontSize: "20px", marginRight: "5px" }}></Link>

                                                    <Link className="single-link" to="tel:+918420041111"> +91 84200 41111</Link>
                                                </li>

                                                <li className="li"><Link className="ion-email-unread" title="Linkedin" to="/" style={{ fontSize: "20px", marginRight: "5px" }}></Link>

                                                    <Link className="single-link" to="mailto:admin@asanafurniture.com" style={{textTransform:'lowercase'}}> admin@asanafurniture.com</Link>
                                                </li>

                                                <li className="li"><Link className="ion-ios-location" title="Linkedin" to="/" style={{ fontSize: "20px", marginRight: "5px" }}></Link>

                                                    <Link className="single-link" to="/">PS Ventures </Link>
                                                    <Link className="single-link" to="/">107/1 Park Street, Kolkata</Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="copy-text" style={{ textAlign: "center" }}> © 2024 Asana Furniture<Link className="company-name" to="https://www.innovination.com/" >
                                    <strong> Powered By Innovination</strong></Link>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FloatingWhatsApp
            phoneNumber="+918420041111"
            accountName="Asana Furniture"
            avatar={logowhite}
            statusMessage="Typically replies within 1 hour"
            chatMessage="Hello! How can we help you today?"
            placeholder="Type your message..."
            allowClickAway
            notification
        />
    </>
}